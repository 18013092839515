<template>
    <div :class="`${size}`">
        <v-card>
            <v-card-title>
                Google Drive
            </v-card-title>
            <div class="wrapper">
                <v-list two-line class="pt-0">
                    <v-list-item-group>
                            <template v-for="doc in documents">
                                <v-list-item :key="`GDA-${doc.id}`" class="selectedItem">
                                    <v-list-item-icon class="my-3">
                                        <v-img :src="`${doc.urlImg}`" :width="doc.width"></v-img>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title class="font-weight-bold">{{ doc.title }}</v-list-item-title>
                                        <v-list-item-subtitle class="text--primary">Modifié le {{ doc.date }} par John Doe</v-list-item-subtitle>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                    </v-list-item-group>
                </v-list>
            </div>
        </v-card>
    </div>    
</template>

<script>
export default {
    name : 'GoogleDrive',
    props : {
        size : {
            type : String,
            default : 'sm'
        }
    },
    data() {
        return {
            documents : [
                { id : 1, title : 'Document texte 1', date : '12/02/2021', urlImg : "/img/google-docs.png", width : 40 },
                { id : 2, title : 'Document sheet 1', date : '12/02/2021', urlImg : "/img/google-sheets.png", width : 40 },
                { id : 3, title : 'Document sheet 2', date : '12/02/2021', urlImg : "/img/google-sheets.png", width : 40 },
                { id : 4, title : 'Document texte 2', date : '12/02/2021', urlImg : "/img/google-docs.png", width : 40 },
                { id : 5, title : 'Document sheet 3', date : '12/02/2021', urlImg : "/img/google-sheets.png", width : 40 },
                { id : 6, title : 'Document forms 1', date : '12/02/2021', urlImg : "/img/google-forms.png", width : 40 },
                { id : 7, title : 'Document slide 1', date : '12/02/2021', urlImg : "/img/google-slides.png", width : 40 },
            ]
        }
    }
}
</script>

<style lang="scss" scoped>
.widget {
    @media screen and (max-width: 600px) {
        grid-column: span 2;
        grid-row: span 3;   
    }
    @media screen and (min-width: 600px) {
        grid-column: span 2;
        grid-row: span 2;
    }
    @media screen and (min-width: 1200px) {
        &.xs {
            grid-column: span 2;
            grid-row: span 2;
        }
        &.sm {
            grid-column: span 2;
            grid-row: span 4;
        }
        &.md {
            grid-column: span 2;
            grid-row: span 6;
        }
        &.lg {
            grid-column: span 4;
            grid-row: span 4;
        }
        &.xl {
            grid-column: span 4;
            grid-row: span 6;
        }
        &.xmd {
            grid-column: span 8;
            grid-row: span 6;
        }
    }
}

.selectedItem {
    &::before {
        content: "";
        position: absolute;
        opacity: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 0.4em;
        background-color: rgb(0, 153, 255);
    }

    &:hover {
        &::before {
            opacity: 1;
        }      
    }
}
</style>